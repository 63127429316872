.phone .box {
    width:375px;
    height: 667px;
    margin: auto;
    border:2px solid rgba(255, 255, 255, .9);
    position: relative;
    border-radius:4px;
    box-sizing: content-box;
}

.phone .box:after {
    content:'';
    position:absolute;
    top:-13%;
    left:-6.5%;
    height:125%;
    width:calc(113% - 8px);
    border:4px solid rgba(255, 255, 255, .75);
    border-radius:60px;
}

.phone .box frame {
    position: absolute;
    top:0;
    left:0;
    width: 100% !important;
    height: 100% !important;
    z-index: 200;
}

.phone .earSpeaker {
    position: absolute;
    top:-34px;
    left:50%;
    transform:translateX(-50%);
    height: 8px;
    width:60px;
    border:2px solid rgba(255, 255, 255, .75);
    border-radius:20px;
}

.phone .earSpeaker:before,
.phone .earSpeaker:after {
    content:'';
    position:absolute;
    width:4px;
    height:4px;
    border:2px solid rgba(255, 255, 255, 0.75);
    border-radius:4px;
}

.phone .earSpeaker:before {
    top:-24px;
    left:50%;
    transform:translateX(-50%);
}

.phone .earSpeaker:after {
    top:-2px;
    left:-24px;
}

.phone .toggleSwitch {
    position:absolute;
    height:34px;
    width:3px;
    background:rgba(255, 255, 255, .75);
    top:14px;
    left:calc(-6.5% - 3px);
    border-radius:2px 0 0 2px;
}

.phone .volumeRocker {
    position:absolute;
    width:3px;
    height:50px;
    background:rgba(255, 255, 255, .75);
    top:90px;
    left:calc(-6.5% - 3px);
    border-radius:2px 0 0 2px;
}

.phone .volumeRocker:after {
    content:'';
    position: absolute;
    width:3px;
    height: 50px;
    display: block;
    background:rgba(255, 255, 255, .75);
    top:calc(100% + 14px);
    left:0;
}

.phone .powerBtn {
    position:absolute;
    height:60px;
    width:3px;
    background:rgba(255, 255, 255, .75);
    top:90px;
    right:calc(-6.5% - 3px);
    border-radius:0 2px 2px 0;
}

.phone .homeBtn {
    position:absolute;
    top:calc(100% + 14px);
    left:50%;
    transform:translateX(-50%);
    height:56px;
    width:56px;
    border-radius:56px;
    border:3px solid rgba(255, 255, 255, 0.5);
    z-index: 100;
}