.template {
    background: yellow;
}

.template .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 50px;
    background: green;
}

.template .feed {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    align-items: center;
}

.template .feed-item {
    background: blue;
    width: 450px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.template .feed-item .src {
    overflow-wrap: break-word;
    font-size: 20px;
}

.template .next-box {
    background: grey;
}