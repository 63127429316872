.login .box {
    height: 100%;
    width: 100%;
}

.login .box {
    width: 400px;
    height: max-content;
    padding: 50px;
    display: flex;
    flex-direction: column;
    background: #fff;
}

.login .separator {
    margin-top: 30px !important;
}