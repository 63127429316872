.dupes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: calc( 100vw - 80px );
    width: 100%;
    margin-left: 60px;
    z-index: 1;
}

.dupes .dupe-container {
    display: flex;
    flex-wrap: wrap;
}

.dupes .dupe-card {
    background: rgb(81, 81, 81);
    border-radius: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    min-width: 600px;
}

.dupes .dupe-button, .dupe-manage .dupe-button {
    background: rgb(66, 66, 66);
    width: 120%;
    padding: 5px;
    margin: -10px;
    margin-top: 0px;
    display: flex;
    justify-content: flex-start;
}

.dupes .dupe-button.dupe-button button {
    margin-right: 10px;
}

.dupes .dupe-title {
    background: rgba(150, 150, 150, 0.5);
    margin-bottom: 10px;
    margin-left: -12px;
    padding: 12px;
    border-radius: 0 10px 10px 0;
}

.dupes .dupe-title:first-child {
    margin-top: 10px;
}

.dupe-manage {
    display: flex;
    align-items: flex-start;
    overflow-x: scroll;
    height: 100%;
}

.dupe-manage .dupe-card {
    background: rgb(81, 81, 81);
    border-radius: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    min-width: 600px;
}

.dupe-manage .dupe-card .on{
    height: 8px;
    width: 8px;
    margin: 5px;
    background: #78d018;
    border-radius: 100%;
}

.dupe-manage .dupe-card .off{
    height: 8px;
    width: 8px;
    margin: 5px;
    background: #d01818;
    border-radius: 100%;
}