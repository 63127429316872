.main-container {
    margin-top: 70px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: auto;
}

.edit-container {
    margin: 0;
    margin-bottom: 20px;
    border-radius: 0;
}

.edit-container-anim {
    overflow: hidden;
}

.edit-container-anim span:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right, transparent, rgb(86 72 145));
    animation: borderanim1 2s linear infinite;
    box-shadow: 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
}
@keyframes borderanim1 {
    0%{
        transform: translateX(-100%);
    }
    100%
    {
        transform: translateX(100%);
    }
}
@keyframes glow {
    from {

    }
}

.edit-container-anim span:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to bottom, transparent, rgb(86 72 145));
    animation: borderanim2 2s linear infinite;
    animation-delay: 1s;
    box-shadow: 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
}
@keyframes borderanim2 {
    0%{
        transform: translateY(-100%);
    }
    100%
    {
        transform: translateY(100%);
    }
}

.edit-container-anim span:nth-child(3) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to left, transparent, rgb(86 72 145));
    animation: borderanim3 2s linear infinite;
    box-shadow: 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
}
@keyframes borderanim3 {
    0%{
        transform: translateX(100%);
    }
    100%
    {
        transform: translateX(-100%);
    }
}

.edit-container-anim span:nth-child(4) {
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to top, transparent, rgb(86 72 145));
    animation: borderanim4 2s linear infinite;
    animation-delay: 1s;
    box-shadow: 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
}
@keyframes borderanim4 {
    0%{
        transform: translateY(100%);
    }
    100%
    {
        transform: translateY(-100%);
    }
}

.post-top .text {
    display: flex;
    flex-direction: column;
}

.post-box {
    width: 100%;
    justify-content: space-between;
    display: flex;
    margin-top: 70px;
}

.post-panel {
    width: 350px;
    place-self: center;
    color: black;
    background: var(--l-blue);
    margin-bottom: 16px;
    border-radius: 0 8px 8px 0;
    left: 0;
    padding: 20px;
    height: max-content;
    box-shadow: 0px 10px 20px -10px rgb(0 0 0 / 75%);
    display: flex;
    flex-direction: column;
}

.body-editor {
    margin: auto;
    width: 85%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    height: 85%;
    background: var(--l-blue);
    border-radius: 5px;
    box-shadow: 0px 10px 20px -10px rgb(0 0 0 / 75%);
    padding: 10px;
    display: flex;
    transition: all .5s ease-in-out;
    flex-direction: column;
}

.body-editor .media-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.body-editor .media-buttons {
    display: flex;
}

.body-editor .media-item {
    background: white;
    border-radius: 5px;
    padding: 10px;
    color: black;
    text-align: left;
    max-width: 400px;
    margin-bottom: 10px;
}

.post-checkbox {
    text-align: left;
    font-size: 16px;
    display: flex;
}

.post-checkbox input {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.post-date {
    text-align: left;
    font-size: 16px;
    display: flex;
}

.post-date input {
    height: 25px;
    margin-right: 10px;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    border: none;
}

.post-slider {
    text-align: left;
    font-size: 16px;
    display: flex;
}

.post-slider input {
    height: 20px;
    width: 150px;
    margin-right: 10px;
}

.unique-panel {
    width: 350px;
    background: var(--l-blue);
    margin-bottom: 16px;
    border-radius: 8px 0 0 8px;
    left: 0;
    padding: 20px;
    place-self: center;
    height: max-content;
    box-shadow: 0px 10px 20px -10px rgb(0 0 0 / 75%);
}

.drag-container {
    background-color: #231E39;
    border-radius: 5px;
    box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.75);
    color: #B3B8CD;
    position: relative;
    width: 850px;
    max-width: 100%;
    margin: 20px;
    text-align: left;
}

.ad-container.ad-container {
    background-color: #231e3982;
    border-radius: 5px;
    box-shadow: 0px 10px 20px -10px rgb(0 0 0 / 75%);
    color: #B3B8CD;
    position: relative;
    width: 450px;
    max-width: 100%;
    border: 2px dashed #0000008f;
    margin-left: 405px !important;
}

.drag-container .unlink {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 100%;
}

.drag-container h1 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    margin-top: 10px;
}

.drag-container h2 {
    font-size: 20px;
    font-weight: 400;
    float: left;
}

.drag-container .square {
    position: relative;
    height: 70px;
    width: 70px;
    margin: 5px 10px;
    overflow: hidden;
}

.drag-container .square .noSrc {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
}

.drag-container .square img, .drag-container .square video  {
    padding: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 120px;
    max-width: 120px;
    width: auto;
    height: auto;
}

.drag-button {
    background-color: #03BFCB;
    border: 1px solid #03BFCB;
    border-radius: 3px;
    color: #231E39;
    padding: 5px 10px;
    width: 100px;
    text-align: center;
    font-size: 16px;
    text-decoration: none;
}

.drag-button.ghost {
    background-color: transparent;
    color: #02899C;
}

.drag-container .second {
    background-color: #1F1A36;
    text-align: left;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
}

.drag-panel {
    display: flex;
}

.drag-order {
    background-color: #1F1A36;
    width: 50px;
    display: flex;
    justify-content: center;
    place-items: center;
}

.lightsOut {
    opacity: 0.1;
    transition: opacity .15s linear;
}

.top-bar {
    position: absolute;
    top: 50px;
    background: #231E39;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 10px 20px -10px rgb(0 0 0 / 75%);
}

.top-bar .buttons {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    height: 100%;
    cursor: pointer;
    margin-left: -1px;
    width: 200px;
    outline: none;
}

.top-bar .button-end {
    height: 100%;
    cursor: pointer;
    margin-left: -1px;
    width: 200px;
    border-radius: 0 0 8px 0;
}

.top-bar .selected {
    box-shadow: inset 0 0 25px 2px rgb(0 0 0 / 60%)
}

.cont-post-edit{
    width: 680px;
}

.cont-post-edit-pnmax{
    width: 680px;
    padding-left: 8px;
    background-color: white;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
}

.cont-post-edit-pnstd{
    position: relative;
    padding:8px;
    background-color: white;
    width:680px;
}