@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.F0F {
  font-size: 400px;
  text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15), 2px 2px 2px rgba(206,89,55,0);
}

.testing.testing {
  color: white;
}

.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  background-color: var(--l-blue-dark1);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  color: white;
}

.App:not(:has(.TranslationEngine_container)) {
  justify-content: center;
}

.App.noScroll{
  height: 100vh;
  overflow: hidden;
}

.hidden {
  opacity: 0;
}

.hide {
  display: none !important;
}

.logout {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-right: none !important;
  margin-left: auto !important;
}

.header-bar .button a, button {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  background: transparent;
  text-decoration: none;
  border-style: none;
  padding: 10px;
  outline: none;
  cursor: pointer;
}

.header-bar p {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  background: transparent;
  text-decoration: none;
  border-style: none;
  padding: 10px;
  outline: none;
  cursor: pointer;
}

.header-space {
  margin-top: 50px;
}

.MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.pagination-container {
  position: absolute;
  left: 50px;
  bottom: 10px;
  display: flex;
  width: calc(100% - 50px);
  justify-content: center;
}

.content-header {
  height: 60px;
  width: calc(100% - 50px);
  position: absolute;
  top: 0;
  left: 50px;
  padding: 10px;
  background:rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: space-between
}

.content-header a {
  display: flex;
}

.profiles-container {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 50px);
  max-height: calc(100vh - 130px);
  overflow: hidden;
  overflow-y: auto;
  margin-left: 50px;
}

.profiles-container .card {
  width: 340px;
  height: 230px;
  margin-bottom: 20px;
  margin-left: 27px;
  position: relative;
}

.profiles-container .card .items-wrapper {
  border-radius: 5px;
  overflow: hidden;
  border: 3px #00000038 solid;
}

.profiles-container .card .text {
  background: #515151;
}

.profiles-container .card .text .title {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: left;
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.profiles-container .card .text .date {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-align: left;
  word-break: break-all;
}

.profiles-container .card .actions {
  background: #424242;
  display: flex;
  padding: 5px;
}

.profiles-container .card .actions a {
  display: flex;
  padding-right: 5px;
}

.profiles-container .card .tags {
  position: absolute;
  top: 5px;
  right: -20px;
}

.profiles-container .card .text {
  padding: 0 5px;
}

.profiles-container .card .src-warning {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 9;
  background: #000000ab;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.profiles-container .mediaCard {
  height: 140px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.profiles-container .onFeed {
  filter: brightness(0.5);
}

.profiles-container .onFeed:before {
  content: "IN FEED";
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

.profile-container {
  background-color: #231E39;
  border-radius: 5px;
  box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.75);
  color: #B3B8CD;
  position: relative;
  width: 330px;
  max-width: 100%;
  text-align: center;
  margin: 20px;
}

.profile-container .image {
  display: flex;
  justify-content: center;
  background: #ffffff21;
  padding: 10px 0;
}

.profile-container h1 {
  font-size: 24px;
  font-weight: 500;
}

.profile-container .master {
  position: absolute;
  top: 0;
  background: #1F1A36;
  padding: 5px;
}

.profile-container .date {
  position: absolute;
  top: 0;
  right: 0;
  background: #1F1A36;
  padding: 5px;
}

.profile-container h2 {
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  padding: 5px;
  border-bottom: 1px solid #0000007a;
}

.MuiButton-root.MuiButton-root.MuiButton-root {
  min-width: unset;
  margin: 0;
}

.profile-container .round img {
  padding: 7px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 360px;
  max-width: 360px;
  width: auto;
  height: auto;
}

.profile-button {
  background-color: #03BFCB;
  border: 1px solid #03BFCB;
  border-radius: 3px;
  color: #231E39;
  padding: 10px 25px;
  font-size: 16px;
  text-decoration: none;
}

.profile-button.ghost {
  background-color: transparent;
  color: #02899C;
}

.profile-container .second {
  background-color: #1F1A36;
  text-align: left;
  padding: 15px;
  margin-top: 5px;
  display: flex;
  justify-content: space-evenly;
}

.user-box {
  position: fixed;
  z-index: 11;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  top: 0;
  display: flex;
}

.user-container-closed {
  transition: all .5s ease-in-out;
}

.user-container {
  margin: auto;
  width: 400px;
  height: max-content;
  background: #62b3bd;
  border-radius: 5px;
  box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.75);
  padding: 10px;
  display: flex;
  transition: all .5s ease-in-out;
  flex-direction: column;
}

.edit-item-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: max-content;
  background: var(--l-blue-light2);
  border-radius: 5px;
  padding: 10px 0;
  display: flex;
  transition: all .5s ease-in-out;
  flex-direction: column;
}

.edit-item-container .post-src {
  max-height: 50vh;
  overflow: hidden;
}

.link-container {
  margin: auto;
  width: 95%;
  height: 95%;
  background: var(--l-blue);
  border-radius: 5px;
  box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.75);
  padding: 10px;
  display: flex;
  transition: all .5s ease-in-out;
  flex-direction: column;
}

.admin-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.admin-container {
  background-color: #231E39;
  box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.75);
  color: #B3B8CD;
  padding-top: 10px;
  position: relative;
  width: 31%;
  max-width: 100%;
  text-align: center;
  margin: 20px;
}

.admin-container .admin {
  background: #ff5454;
  height: 30px;
  padding: 0 10px;
  font-size: 20px;
  align-self: center;
  border: 1px solid rgba(0,0,0,0.5);
  margin-left: 10px;
  color: black;
}

.admin-container .content {
  background: #54afff;
  height: 30px;
  padding: 0 10px;
  font-size: 20px;
  align-self: center;
  border: 1px solid rgba(0,0,0,0.5);
  margin-left: 10px;
  color: black;
}

.admin-container .socials {
  background: #d9de60;
  height: 30px;
  padding: 0 10px;
  font-size: 20px;
  align-self: center;
  border: 1px solid rgba(0,0,0,0.5);
  margin-left: 10px;
  color: black;
}

.admin-container .id {
  padding-top: 40px;
  width: 50px;
  margin-top: -10px;
  margin-bottom: -10px;
  background: #1F1A36;
  font-size: 30px;
  font-weight: 700;
  text-shadow: 5px 5px 3px #000000a1;
}

.admin-container .round {
  position: relative;
  height: 90px;
  width: 90px;
  margin: 0 20px;
  overflow: hidden;
  border: 1px solid #03BFCB;
}

.admin-container .flex {
  display: flex;
}

.admin-container .round img {
  padding: 7px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100px;
  width: auto;
  height: auto;
}

.admin-container .second {
  background-color: #1F1A36;
  text-align: left;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
}

.box > .tok-frame > div {
  width: 100%;
}
