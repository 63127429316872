* {
    box-sizing: border-box;
}

.main-tree {
    background-color:#6ab4e3;
    width:600px;
    height:600px;
    border:5px solid #ddd;
    border-bottom:none;
    border-radius:100%;
    margin: 25px auto;
    padding: 0;
    position: relative;
    overflow: hidden!important;
}

.home-text {
    position: absolute;
    left: 50%;
    top: 100px;
    z-index: 1;
    transform: translate(-50%, -50%);
    color: #f70094;
    animation-name: flash-3-text;
    animation-duration: 1.1s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}

.home-text-normal {
    font-size: 50px;
    text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb;
}

.main-tree::before {
    content: '';
    width:550px;
    height: 160px;
    border-radius: 10px 100% 50px 30px;
    padding: 0;
    margin: 0;
    background: #fff;
    position: absolute;
    bottom:0px;
    left:0;
    z-index:0;
    box-shadow: inset -5px 10px 10px -5px #cbd7e6, inset -15px 5px 25px 0px #b3c5da,  inset -30px 30px 60px 10px #d7dce2, inset -10px 25px 22px 10px #f5efe7
}

.main-tree::after {
    content: '';
    width:400px;
    height: 130px;
    border-radius: 100% 50% 10px 30px;
    padding: 0;
    margin: 0;
    background: #fff;
    position: absolute;
    z-index:0;
    bottom:-15px;
    left:calc(50% - 210px);
    box-shadow: inset -5px 10px 10px #cbd7e6, inset -10px 20px 25px 10px #d7dce2,

    130px 35px 30px -30px #fff,  145px 20px 20px -20px #f5efe7,  150px 20px 0 -15px #b3c5da, 150px 20px 10px -16px #fff,

    -130px -5px 30px -10px #fff, -150px -20px 0 -10px #b3c5da
}


.tree-wrap {
    position: relative;
    width:100%;
    height: 366px;
    margin-top: 125px;
}

.tree-wrap::after {
    content: '';
    width: 50px;
    height: 40px;
    padding: 0;
    margin: 0;
    background: #611919;
    position: absolute;
    bottom:0;
    left:calc(50% - 25px);
    box-shadow: inset -10px 10px 22px 2px black,  inset 10px -10px 15px 0 #a56f20

}


.star {
    margin: 0;
    position: absolute;
    display: block;
    color: yellow;
    width: 0px;
    height: 0px;
    border-right: 100px solid transparent;
    border-bottom: 70px solid yellow;
    border-left: 100px solid transparent;
    transform: rotate(35deg) scale(.25);

    top:-10px;
    left:calc(50% - 100px);

}
.star:before {
    border-bottom: 80px solid yellow;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    position: absolute;
    height: 0;
    width: 0;
    top: -45px;
    left: -65px;
    display: block;
    content: '';
    transform: rotate(-35deg);
}
.star:after {
    position: absolute;
    display: block;
    color: red;
    top: 3px;
    left: -105px;
    width: 0px;
    height: 0px;
    border-right: 100px solid transparent;
    border-bottom: 70px solid yellow;
    border-left: 100px solid transparent;
    transform: rotate(-70deg);
    content: '';
}

.tree {
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    border-bottom: 100px solid #174821;
    transform: rotateY(30deg);
    transform-origin: center;

    position: absolute;
    z-index: 9;
    top: 35px;
    left: calc(50% - 100px);

    list-style: none;
}

.middle {
    transform: rotateY(30deg) scale(1.4);
    transform-origin: center;
    position: absolute;
    z-index: 8;
    top: 100px;

}

.bottom {
    transform: rotateY(30deg) scale(2);
    transform-origin: center;
    position: absolute;
    z-index: 7;
    top: 180px;
}


.tree li {
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    position: absolute;
    top: 0px;
    z-index: 1;
}

.tree li:first-child {
    border-left: 100px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 100px solid #1f781d;
    transform: rotateY(40deg) rotateZ(-5deg);
    transform-origin: top right;

    left: calc(50% - 100px)
}


.tree li:nth-child(2) {
    border-left: 100px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 100px solid #559c5a;
    transform: rotateY(65deg) rotateZ(-9deg);
    transform-origin: top right;

    left: calc(50% - 100px);
}

.tree li:nth-child(4) {
    border-left: 0px solid transparent;
    border-right: 100px solid transparent;
    border-bottom: 100px solid #1f781d;
    transform: rotateY(-75deg) rotateZ(9deg);
    transform-origin: bottom left;

}

.tree li:nth-child(3) {
    border-left: 0px solid transparent;
    border-right: 100px solid transparent;
    border-bottom: 100px solid #1b612b;
    transform: rotateY(-50deg) rotateZ(5deg);
    transform-origin: bottom left;

}


.lightrope {
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 9;
    top: -65px;
    right: calc(50% - 250px);
    width: 500px;
    height: 500px;
}
.lightrope li {
    position: absolute;
    z-index:10;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    top:0;
    display: block;
    width: 2%;
    height: 3%;
    border-radius: 50%;
    background: #00f7a5;
    box-shadow: 0px 4.66667px 24px 3px #00f7a5;
    animation-name: flash-1;
    animation-duration: 2s;
}

.lightrope li:nth-child(2n+1) {
    background: cyan;
    box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.5);
    animation-name: flash-2;
    animation-duration: 0.4s;
}

.lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.66667px 24px 3px rgba(253, 2, 153, 1);
    animation-name: flash-3;
    animation-duration: 1.1s;
}

.lightrope li:nth-child(odd) {
    animation-duration: 1.8s;
}

.lightrope li:nth-child(3n+1) {
    animation-duration: 1.4s;
}

.lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 100%;
    height: 40%;
    border-radius: 3px;
    top: -2px;
    left: 0px;
}


@keyframes flash-1 {
    0%, 100% {
        background: #eaea18;
        box-shadow: 0px 4.66667px 24px 3px #eaea18;
    }
    50% {
        background: rgba(234, 234, 24, 0.4);
        box-shadow: 0px 4.66667px 24px 3px rgba(234, 234, 24, 0.2);
    }
}
@keyframes flash-2 {
    0%, 100% {
        background: cyan;
        box-shadow: 0px 4.66667px 24px 3px cyan;
    }
    50% {
        background: rgba(0, 255, 255, 0.4);
        box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.2);
    }
}
@keyframes flash-3 {
    0%, 100% {
        background: #f70094;
        box-shadow: 0px 4.66667px 24px 3px pink;
    }
    50% {
        background: rgba(253, 2, 153, 0.4);
        box-shadow: 0px 4.66667px 24px 3px rgba(234, 234, 24, 0.2);
    }
}
@keyframes flash-3-text {
    0%, 100% {
        color: #f70094;
    }
    50% {
        color: rgba(253, 2, 153, 0.4);
    }
}
