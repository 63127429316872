.TranslationEngine_container {
  font-size: 1rem;
  background-color: #000041;
  border: #1976d2 1px solid;
  border-radius: 5px;
  min-width: 85%;
 
  margin: 0 auto;
  width: 95%;

  max-height: 100vh;
}

.MuiButton-root.MuiButton-root.MuiButton-root.button{
  min-width: 5%;
  height: 2.5rem;
  margin: 0.7rem;
  font-size: 0.75rem;
}

.MuiButton-root.MuiButton-root.MuiButton-root.button:disabled{
  background-color: grey;
  color: white;
}

/* ####################### simpleEntryModal ####################### */

.simpleModal {
  background-color: #000041;
  position: absolute;
  top: 18%;
  left: 38%;
  border: #1976d2 4px solid;
  border-radius: 5px;
  min-width: 26%;
  z-index: 2;
}
.simpleModal h2 {
  margin-top: 1rem;
  font-size: 1.3rem;
}
.simpleModal-input {
  min-width: 60%;
  height: 3rem;
  border: #1976d2 3px solid;
  border-radius: 5px;
  margin: 2rem 1rem 0;
  padding: 0.5rem;
  font-size: 1rem;
}
.buttonStyles{
  margin: 1rem auto 1.5rem;
  justify-content: space-around;
  /* position: relative; */
  display: flex;
}


/* ####################### DropDown Lists ####################### */

.DropDown_Container {
  width:50%;
  padding: 4px;
  border: #1976d2 solid 1px;
  height: 6.5rem;
}
.DropDown_Container.right{
  border-bottom: none;
}
h3{
  text-align: left;
  padding-left: 0.5rem;
}
h4{
  text-align: left;
  padding-left: 1rem;
  font-weight: 500;
}
.DropDown_Button_Container{
  display: flex;
  justify-content: space-between;
}
.DropDown_selectRow {
  display: flex;
} 
.DropDown_List {
  background-color: #FFF;
  border: #1976d2 1px solid;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  width: 280px;
  margin: 1rem auto 0 0.5rem;
}

.DropDown_Height{
  max-height: 65vh;
  overflow: auto;
  padding-bottom: 1rem;
 
}


/* ##################### LanguageDisplay ####################### */

.languageDisplay {
 
  display: flex;
}
.languageDisplay_Display{
margin-top: 1rem;
  min-width: 50%;
}
.languageDisplay_grid{
   display: grid;
  grid-template-columns: 20% 58% 17% 5%;
  justify-content: space-between;
  height: 24px;
   width: 98.5%;
  margin: 0  0 0 0.5rem;

}
.languageDisplay_grid.header{
 
  width: 98%;
 margin: 0  0 0 1rem;

}
.languageDisplay_Title {
  text-align: start;
  color: #fff;
  font-size: medium;
  /* padding-left: 0.5rem; */
}
.languageDisplay_styles {
 text-align: start;
  color: black;
  margin: 0.5rem 0 0 0.3rem;
padding-left: 0.5rem;
height: 1.5rem;
}

.languageDisplay_styles.left{
  height: 1.5rem;
}
.MuiButton-root.MuiButton-root.MuiButton-root.languageDisplay_addBtn{
  margin: 0.5rem auto 0;
  width: 90%;
  height: 53%;
  justify-content: center;
}
.display_height{
  height: 24px;
  margin-top: 0.5rem;
}
.MuiButton-root.MuiButton-root.MuiButton-root.languageDisplay_saveBtns{
  margin: 1rem auto 1rem;
}


/* ************   warningModal   ************ */

.warningModal {
 height: 18.25rem;
  background-color: #000041;
  position: absolute;
  top: 10%;
  left: 32.5%;
  border: #1976d2 8px solid;
  border-radius: 5px;
  width: 32rem;
  z-index: 5;
}
.padding {
  padding: 2rem;
}
.warningModal h3{
  text-align: center;
}
.warningModal_fontColor{
  color: #1976d2;
  font-size: 1rem;
}
.warningModal_btnContainer{
  width: 65%;
    margin: 0 auto;
    justify-content: space-between;
    display: flex;
}

.MuiButton-root.MuiButton-root.MuiButton-root.warningModal-btn {
  padding: 0.8rem 1rem;
  margin-bottom: 1rem;
}

