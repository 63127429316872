@import "login.css";
@import "header.css";
@import "social.css";
@import "phoneview.css";
@import "template.css";
@import "edit.css";
@import "dupes.css";
@import "tags.css";
@import "profile.css";
@import "lang.css";
@import "home.css";

/* Colors */

:root {
  --l-blue: #00b9db;
  --l-blue-light1: #2ddeff;
  --l-blue-light2: #5be5ff;
  --l-blue-dark1: #004b59;
  --l-blue-dark2: #002f38;

  --l-red: #fd0013;
  --l-red-light1: #ff5763;
  --l-red-light2: #ff2838;
  --l-red-dark1: #8d000a;
  --l-red-dark2: #580006;

  -l-yellow: #ffba00;
  -l-yellow-light1: #ffc528;
  -l-yellow-light2: #ffc528;
  -l-yellow-dark1: #906900;
  -l-yellow-dark2: #5b4200;
}

/* Global */

button {
  outline: none !important;
}

a {
  text-decoration: none !important;
  color: inherit;
}

.trashMan {
  width: 700px;
  margin: -10px;
  height: 20vh;
  position: fixed;
  top: -150vh;
  z-index: -1;
}

.hoverBot {
  width: 700px;
  margin: -10px;
  height: 20vh;
  position: fixed;
  top: 40vh;
  z-index: -1;
}

.nextBot {
  width: 700px;
  margin: -10px;
  height: 20vh;
  position: fixed;
  bottom: -30vh;
  z-index: -1;
}

.next-button {
  background-color: #4caf50;
  margin: 20px;
  border: none;
  color: white;
  outline: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.4s;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.next-container {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  height: 100%;
  z-index: 1000;
  align-items: center;
  display: flex;
  justify-content: center;
}

.foreInputWhite div {
  color: white;
}

.foreInputWhite input {
  color: white;
}

.foreInputWhite svg {
  color: white;
}