.lang {
    display: flex;
    align-items: center;
    max-width: calc(100vw - 50px);
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: space-around;
}

.lang .infoBox {
    margin-left: 10px;
    background: rgba(0, 0, 0, 0.65);
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    justify-content: space-evenly;
}

.lang .infoBox .head {
    font-size: 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 10px;
    text-align: left;
    background: #1976d2;
    justify-content: center;
}

.lang .infoBox .items {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.lang .infoBox .first {
    margin-top: 30px;
}

.lang .infoBox .items ul {
    margin-left: 5px;
}

.lang .infoBox .items li {
    text-align: left;
}

.lang .infoBox .title {
    margin-right: auto;
    margin-left: -20px;
    padding-right: 30px;
    padding-left: 10px;
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
    font-size: 20px;
    width: 220px;
    text-align: left;
    background: #1976d2;
}