.profile {
    display: flex;
    align-items: center;
}

.profile .infoBox {
    background: rgba(0, 0, 0, 0.65);
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    justify-content: space-evenly;
}

.profile .infoBox .id {
    position: absolute;
    top: 0;
    left: 0;
    background: #1976d2;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    padding: 5px 30px 30px 10px;
}

.profile .infoBox .title {
    margin-right: auto;
    margin-left: -20px;
    padding-right: 30px;
    padding-left: 10px;
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
    font-size: 20px;
    width: 220px;
    text-align: left;
    background: #1976d2;
}

.profile .editBox {
    background: rgba(0, 0, 0, 0.4);
    padding: 10px;
    border-radius: 0 6px 6px 0;
    width: 250px;
    height: 375px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}